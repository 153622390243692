import { API_URL } from '@/constants/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface GetProgramsParams {
  page?: number;
  search?: string;
  university?: string;
  studyLevel?: string;
  courseForm?: string;
  year?: string;
  intake?: string;
  fee?: string;
  major?: string;
}

export const programsApi = createApi({
  reducerPath: 'programsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: (params) => {
        const { page = 1, ...filters } = params;
        const queryParams = new URLSearchParams({ page: page.toString() });

        (
          Object.entries(filters) as [keyof GetProgramsParams, string][]
        ).forEach(([key, value]) => {
          if (value) {
            queryParams.append(key === 'search' ? 'programName' : key, value);
          }
        });

        return `admission-programs-with-filters?${queryParams.toString()}`;
      }
    })
  })
});

export const { useGetProgramsQuery } = programsApi;
// import { API_URL } from '@/constants/config';
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// export const programsApi = createApi({
//   reducerPath: 'programsApi',
//   baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
//   endpoints: (builder) => ({
//     getPrograms: builder.query({
//       query: ({
//         page = 1,
//         search,
//         university,
//         studyLevel,
//         courseForm,
//         year,
//         intake,
//         fee,
//         major
//       }) => {
//         const params = new URLSearchParams();

//         // Add query parameters dynamically if they exist
//         params.set('page', String(page));
//         if (search) params.set('programName', search);
//         if (university) params.set('university', university);
//         if (studyLevel) params.set('studyLevel', studyLevel);
//         if (courseForm) params.set('courseForm', courseForm);
//         if (year) params.set('year', year);
//         if (intake) params.set('intake', intake);
//         if (fee) params.set('fee', fee);
//         if (major) params.set('major', major);

//         const url = `admission-programs-with-filters?${params.toString()}`;
//         return { url };
//       }
//     })
//   })
// });

// export const { useGetProgramsQuery } = programsApi;
